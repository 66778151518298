import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_APP_ENV !== "local") {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        tracePropagationTargets: [import.meta.env.VITE_APP_URL],
        environment: import.meta.env.VITE_APP_ENV,
    });
}
