import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from "react";
import { User } from "@/types/page";
import { CookieManagerContext } from "@/Contexts/CookieManagerContext";
import * as amplitude from "@amplitude/analytics-browser";
import { Experiment } from "@amplitude/experiment-js-client";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { getCookie } from "@/utils/CookieJar";

const initialAnalyticsContext = {
    experimentationInitialized: false,
    identifyUser: (_user: User) => {},
    trackEvent: (
        _eventName: string,
        _eventProperties?: Record<string, string | number | boolean | object>,
    ) => {},
    getExperimentVariant: (
        _experimentName: string,
        _fallback?: string,
    ): string | undefined => undefined,
};

export type AnalyticsContextType = typeof initialAnalyticsContext;

export const AnalyticsContext = createContext(initialAnalyticsContext);

export function AnalyticsProvider({ children }: PropsWithChildren) {
    const cookieSettings = useContext(CookieManagerContext).cookieSettings;
    const experimentation = Experiment.initializeWithAmplitudeAnalytics(
        import.meta.env.VITE_AMPLITUDE_API_KEY,
    );
    const [experimentationInitialized, setExperimentationInitialized] =
        useState(false);

    const optOut = () => {
        // remove amplitude cookies (start with AMP_)
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            if (cookies[i].trim().indexOf("AMP_") === 0) {
                const cookieName = cookies[i].split("=")[0];
                document.cookie =
                    cookieName +
                    "=fu; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=." +
                    window.location.hostname;
            }
        }

        amplitude.setOptOut(true);
    };

    const trackEvent = (
        eventName: string,
        eventProperties?: Record<
            string,
            string | number | boolean | object | null
        >,
    ) => {
        if (!isAnalyticsAllowed()) {
            return;
        }
        amplitude.track(eventName, eventProperties);
    };

    const identifyUser = (user: User) => {
        if (!isAnalyticsAllowed()) {
            return;
        }
        const identifier = buildIdentifier(user.type, user.id);
        amplitude.setUserId(identifier);
        const identifyEvent = new amplitude.Identify();
        identifyEvent.set("user_type", user.type);
        identifyEvent.set("user_email", user.email ?? "");
        identifyEvent.set("user_phone", user.phone_e164 ?? "");
        identifyEvent.set("first_name", user.first_name);
        identifyEvent.set("last_name", user.last_name);
        identifyEvent.set("has_payment_setup", user.has_payment_setup ?? false);
        amplitude.identify(identifyEvent);
    };

    const getExperimentVariant = (
        experimentName: string,
        fallback: string = "control",
    ) => {
        if (!experimentationInitialized && isAnalyticsAllowed()) {
            return undefined;
        }
        if (!isAnalyticsAllowed()) {
            return fallback;
        }
        return experimentation.variant(experimentName).value ?? fallback;
    };

    const buildIdentifier = (userType: string, userId: number) => {
        let retVal = userType + "|" + userId;
        if (import.meta.env.VITE_LOCAL_ENVIRONMENT_ID) {
            retVal += "|" + import.meta.env.VITE_LOCAL_ENVIRONMENT_ID;
        }
        return retVal;
    };

    const isAnalyticsAllowed = () => {
        return cookieSettings.analytics;
    };

    useEffect(() => {
        (async () => {
            if (!isAnalyticsAllowed()) {
                optOut();
                setExperimentationInitialized(true);
                return;
            }
            amplitude.setOptOut(false);
            const sessionReplayTracking = sessionReplayPlugin();
            amplitude.add(sessionReplayTracking);
            amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
                autocapture: true,
                deviceId: getCookie("AMP_deviceId", undefined),
            });
            try {
                await experimentation.start();
            } catch (_e) {
                /**
                 * Experimentation failed to start. This is likely due to users running an ad or tracking blocker.
                 * We'll set experimentation initialization as completed, but users will always get the fallback variant.
                 */
            } finally {
                setExperimentationInitialized(true);
            }
        })();
    }, [isAnalyticsAllowed()]);

    return (
        <AnalyticsContext.Provider
            value={
                // If analytics are not allowed, we absolutely do not want to pass functions that actually do
                // anything to the children. This is why we return the initialization object.
                !isAnalyticsAllowed()
                    ? {
                          ...initialAnalyticsContext,
                          // We still want to be able to get experiment variants, so this function needs to manually
                          // check if analytics are allowed.
                          getExperimentVariant,
                          experimentationInitialized,
                      }
                    : {
                          identifyUser,
                          trackEvent,
                          getExperimentVariant,
                          experimentationInitialized,
                      }
            }
        >
            {children}
        </AnalyticsContext.Provider>
    );
}
