import { User } from "@/types/page";

export default function dataLayerUserData(user: User) {
    return {
        user_id: `${user.type}|${user.id}`,
        user_type: user.type,
        user_email: user.email,
        user_phone: user.phone_e164,
        first_name: user.first_name,
        last_name: user.last_name,
        has_payment_setup: user.has_payment_setup,
    };
}
