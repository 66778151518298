import { router } from "@inertiajs/react";
import { PageProps } from "@/types/page";
import dataLayerUserData from "@/utils/dataLayer";

let prevPage: string | null = null;
router.on("navigate", (event) => {
    if (prevPage === null) {
        /**
         * This is the first navigation event, which is triggered when the page
         * is loaded. We want to skip those since analytics already tracks them.
         * We just want to report SPA navigations.
         */
        prevPage = event.detail.page.url;
        return;
    }

    dataLayer.push({
        event: "page_view",
        page_location: event.detail.page.url,
        prev_page_location: prevPage,
    });
});

const pageDataStr = document
    .querySelector("[data-page]")
    ?.getAttribute("data-page");
if (pageDataStr) {
    const props = JSON.parse(pageDataStr).props as PageProps;
    dataLayer.push({
        event: "logged_in_state",
        ...(props.user ? dataLayerUserData(props.user) : []),
    });
}
