import { PropsWithChildren } from "react";
import { CookieManagementProvider } from "@/Contexts/CookieManagerContext";
import { AnalyticsProvider } from "@/Contexts/AnalyticsContext";

export function GlobalStateProviders({ children }: PropsWithChildren) {
    return (
        <CookieManagementProvider>
            <AnalyticsProvider>{children}</AnalyticsProvider>
        </CookieManagementProvider>
    );
}
