import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { router } from "@inertiajs/react";
import { getCookie, setCookie } from "@/utils/CookieJar";
import { AnalyticsContextType } from "@/Contexts/AnalyticsContext";

export type CookieSettings = {
    analytics: boolean;
    advertising: boolean;
};

const initialCookieSettings = {
    analytics: true,
    advertising: false,
};

const initialCookieContext = {
    showCookieSettingsModal: false,
    showCookieBanner: !getCookie("hasEverBeenSaved", false),
    cookieSettings: getCookie("cookie-settings", initialCookieSettings),
    resetCookieSettingsToLastSaved: () => {},
    saveCookieSettingsAndCloseAll: (
        _: CookieSettings,
        __: AnalyticsContextType,
    ) => {},
    setShowCookieSettingsModal: (_: boolean) => {},
    setShowCookieBanner: (_: boolean) => {},
    hasEverBeenSaved: getCookie("hasEverBeenSaved", false),
    analyticsSettingToggleState: initialCookieSettings.analytics,
    advertisingSettingToggleState: initialCookieSettings.advertising,
    setAnalyticsToggleState: (_: boolean) => {},
    setAdvertisingToggleState: (_: boolean) => {},
};

export const CookieManagerContext = createContext(initialCookieContext);

export function CookieManagementProvider({ children }: PropsWithChildren) {
    const [cookieSettings, setCookieSettings] = useState(
        initialCookieContext.cookieSettings,
    );
    const [showCookieSettingsModal, setShowCookieSettingsModal] = useState(
        initialCookieContext.showCookieSettingsModal,
    );
    const [showCookieBanner, setShowCookieBanner] = useState(
        initialCookieContext.showCookieBanner,
    );
    const [hasEverBeenSaved, setHasEverBeenSaved] = useState(
        initialCookieContext.hasEverBeenSaved,
    );
    const [analyticsSettingToggleState, setAnalyticsToggleState] = useState(
        initialCookieContext.cookieSettings.analytics,
    );
    const [advertisingSettingToggleState, setAdvertisingToggleState] = useState(
        initialCookieContext.cookieSettings.advertising,
    );

    const persistToServer = (cookieSettings: CookieSettings) => {
        router.post(route("cookie-settings"), {
            analytics: cookieSettings.analytics,
            advertising: cookieSettings.advertising,
        });
    };

    const saveCookieSettingsAndCloseAll = (
        cookieSettings: CookieSettings,
        analytics: AnalyticsContextType,
    ) => {
        analytics.trackEvent("saved_cookies", {
            analytics_cookie: cookieSettings.analytics,
            advertising_cookie: cookieSettings.advertising,
        });
        dataLayer.push({
            event: "saved_cookies",
            analytics_cookie: cookieSettings.analytics,
            advertising_cookie: cookieSettings.advertising,
        });
        setCookieSettings(cookieSettings);
        setShowCookieSettingsModal(false);
        setShowCookieBanner(false);
        persistToServer(cookieSettings);
        setCookie("hasEverBeenSaved", true);
        setHasEverBeenSaved(true);
    };

    const resetCookieSettingsToLastSaved = () => {
        setAnalyticsToggleState(cookieSettings.analytics);
        setAdvertisingToggleState(cookieSettings.advertising);
    };

    useEffect(() => {
        if (getCookie("cookie-settings", null) !== null) return;
        setCookie("cookie-settings", {
            analytics: true,
            advertising: false,
        });
    }, []);

    return (
        <CookieManagerContext.Provider
            value={{
                showCookieSettingsModal,
                showCookieBanner,
                cookieSettings,
                resetCookieSettingsToLastSaved,
                saveCookieSettingsAndCloseAll,
                setShowCookieSettingsModal,
                setShowCookieBanner,
                hasEverBeenSaved,
                analyticsSettingToggleState,
                advertisingSettingToggleState,
                setAnalyticsToggleState,
                setAdvertisingToggleState,
            }}
        >
            {children}
        </CookieManagerContext.Provider>
    );
}
